import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  ContentContainer,
  ContentColumns,
  Title,
  TextBlock,
  useExitRamp,
} from '@lxlabs/change-ad-components';
import Phone from '../../assets/image/phone-mockup.png';

import GooglePlayBadge from '../../assets/image/google-play-store-logo.svg';
import AppStoreBadge from '../../assets/image/apple-app-store-logo.svg';

const Style = styled(Card)`
  padding: var(--spacing-xxlarge) 0px;
  padding-bottom: 0px;

  @media screen and (max-width: 834px) {
    margin-left: calc(var(--spacing-small) * -1);
    margin-right: calc(var(--spacing-small) * -1);
  }
`;

const Content = styled.div`
  padding-bottom: var(--spacing-xlarge);
  
  @media screen and (max-width: 834px) {
    margin: 0px 10px;
  }
`;

const PhoneScreen = styled.img`
  max-width: 350px;
  width: 100%;
  display: block;
  margin: 0 auto;
  align-self: flex-end;
`;

const Badges = styled.div`
  display: flex;
  gap: var(--spacing-large);
`;

const StoreBadge = styled.button`
  display: block;
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  img {
    height: 42px;
    display: block;
  }
`;

const AppCTA = ({ children, padding }) => {
  const { openExitRamp } = useExitRamp();

  return (
    <ContentContainer padding={{ top: 'none', bottom: 'none', ...padding }}>
      <Style>
        <ContentColumns gapSize="large">
          <Content>
            <Title>Want to keep an eye on your signs and symptoms? Download the app</Title>
            <TextBlock spacing={{ top: 'large', bottom: 'large' }}>
              {children || (
                <>
                  Your symptoms can come and go, which can be confusing. Sometimes your symptoms can
                  really get out of control. But you might experience long stretches without any
                  symptoms at all. That&apos;s why it&apos;s so important to keep track of your
                  symptoms, so that your doctor can see the full picture of your AD, over time.
                </>
              )}
            </TextBlock>

            <Badges>
              <StoreBadge onClick={() => openExitRamp('https://apps.apple.com/gb/app/eztrack-for-atopic-dermatitis/id1501488844')}>
                <img src={AppStoreBadge} alt="Download on the App Store" />
              </StoreBadge>
              <StoreBadge onClick={() => openExitRamp('https://play.google.com/store/apps/details?id=com.sanofi.us.eztrack.prod&hl=en_GB&gl=US')}>
                <img src={GooglePlayBadge} alt="Get it on Google Play" />
              </StoreBadge>
            </Badges>
          </Content>
          <PhoneScreen src={Phone} />
        </ContentColumns>
      </Style>
    </ContentContainer>
  );
};

AppCTA.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  padding: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
};

AppCTA.defaultProps = {
  children: null,
  padding: {},
};

export default AppCTA;
